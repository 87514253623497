// i18next-extract-mark-ns-start about-page

import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <main>
      <p>About Page</p>
      <p>{t("aboutText")}</p>
    </main>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "about-page"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default AboutPage;
